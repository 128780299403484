import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    background: theme.palette.secondary.main,
    padding: theme.spacing(2),
    borderRadius: '10px',
    boxShadow: '2px 5px 3px 1px rgba(0, 0, 0, 0.2)',

  },
}))
