import React from 'react'

const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null)
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])
  return (
    <div
      className="trustpilot-widget"
      data-locale="pt-PT"
      data-template-id="5419b6a8b0d04a076446a9ad"
      data-businessunit-id="6005e4fac770200001ee7d48"
      data-style-height="24px"
      data-style-width="100%"
      data-theme="dark"
    >
      <a
        href="https://pt.trustpilot.com/review/casadobeco.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot

      </a>
    </div>
  )
}
export default TrustBox
