import { createMuiTheme, adaptV4Theme } from '@material-ui/core/styles'

const theme = createMuiTheme(adaptV4Theme(
  {
    palette: {
      primary: {
        main: '#316565',

      },
      secondary: {
        main: '#FFFFFF',
      },
    },
  },
))

export default theme
