import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  title: {
    color: '#333333',
    textTransform: 'uppercase',
    paddingBottom: '20px',
    paddingTop: '40px',
  },
  description: {
    color: '#888888',
    paddingBottom: '20px',
  },
  photo: {
    width: '100%',
  },
}))
