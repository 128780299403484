import React from 'react'

import DateRangePicker from '@material-ui/lab/DateRangePicker'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import DateRangeDelimiter from '@material-ui/lab/DateRangeDelimiter'

import { Grid, TextField } from '@material-ui/core'

export default function OurDatePicker({
  value,
  onChange,
  error,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        startText="Check-in"
        endText="Check-out"
        disableCloseOnSelect={false}
        inputFormat="dd/MM/yyyy"
        reduceAnimations
        value={value}
        onChange={onChange}
        disableHighlightToday
        disablePast
        renderInput={(startProps, endProps) => (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={5}>
              <TextField
                {...startProps}
                variant="standard"
                fullWidth
                error={error != null}
              />
            </Grid>
            <Grid item>
              <DateRangeDelimiter> to </DateRangeDelimiter>
            </Grid>
            <Grid item xs={5}>
              <TextField
                {...endProps}
                variant="standard"
                fullWidth
                error={error != null}
              />
            </Grid>
          </Grid>
        )}
      />
    </LocalizationProvider>
  )
}
