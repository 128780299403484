import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  divider: {
    backgroundColor: '#FFFFFF',
    height: '0.05px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  footerText: {
    marginTop: '5px',
    marginBottom: '5px',
    textAlign: 'center',
    fontSize: '12px',
    color: '#FFFFFF',
  },
}))
