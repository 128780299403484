import React, { useEffect } from 'react'

import tawkTo from 'tawkto-react'

export default function TawkBot() {
  const tawkToPropertyId = '5f8707e9a2eb1124c0bce890'
  useEffect(() => {
    tawkTo(tawkToPropertyId)
  }, [])
  return <></>
}
