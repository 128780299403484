import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    color: '#333333',
    textTransform: 'uppercase',
  },
}))
