import React from 'react'

export default function PrivacyPolicy() {
  return (
    <div style={{ margin: '5px' }}>
      <p><b>Política de Privacidade</b></p>

      <p>A Casa do Beco assume o compromisso de prestar um serviço de qualidade a todos os seus clientes, garantindo a sua total privacidade. Todas as informações pessoais dos nossos clientes são tratadas de acordo com o Regulamento Geral de Proteção de Dados da União Europeia. Pedimos-lhe que leia a nossa Política de Privacidade e colocamo-nos ao dispor para esclarecer qualquer dúvida aqui. O uso do site pressupõe o conhecimento e aceitação da nossa política de privacidade.</p>
      <p><b>Segurança Online</b></p>
      <p>Sempre que a Casa do Beco lhe solicitar dados pessoais, a comunicação é efetuada através de uma linha segura.</p>
      <p> • Cookies </p>
      <p>Cookies são pequenos ficheiros de texto que são enviados para o seu computador quando visita um determinado site. Estes ficheiros permitem uma personalização no acesso à nossa página e melhoram a sua experiência online. A maioria dos motores de pesquisa aceita os cookies, porém pode apagá-los ou definir o seu bloqueio. A Casa do Beco não divulga esta informação.</p>
      <p> • Quem é o responsável pelos dados pessoais?</p>
      <p> O responsável pelo tratamento de dados é a Casa do Beco. Os dados pessoais são processados tendo em conta as finalidades que levaram o nosso cliente a submeter os referidos dados pessoais.</p>
      <p> • Quais os dados pessoais que guardamos e com que finalidade? </p>
      <p> Guardamos apenas os Dados Pessoais dos nossos clientes que sejam necessários para realizar a reserva de alojamento, para manter contacto e para preencher os dados de pagamento de serviços, tais como: nome, morada, número de telefone/telemóvel, email e número de contribuinte. </p>
      <p> • Por quanto tempo são os dados pessoais armazenados?</p>
      <p> Os Dados Pessoais são guardados enquanto se mantiver o fim a que se destinam. Só serão eliminados 2 anos depois da última interação do cliente com os nossos serviços, caso nenhuma disposição legal obrigue a manter esses dados por um período mínimo superior.</p>
      <p> • Como e quando o cliente pode aceder aos dados pessoais?</p>
      <p> O cliente pode aceder aos seus dados a qualquer altura pois tem esse direito, bem como de os retificar ou apagar, cumprindo o disposto na Lei. Para exercer esse direito pode entrar em contacto com a Casa do Beco. </p>

    </div>
  )
}
