import React from 'react'

export default function TermsConditions() {
  return (
    <div style={{ margin: '5px' }}>
      <p><b>Condições Gerais</b></p>
      <p>A Casa do Beco assume que qualquer contacto estabelecido pressupõe por parte do cliente o conhecimento e aceitação das condições gerais que aplicamos ao nosso funcionamento.</p>
      <p>Assim descrevemos as nossas Condições Gerais:</p>
      <p><b>Reservas e Pagamentos</b></p>
      <p>1. Pedido de Informações</p>
      <p>Os pedidos de informação por parte do cliente e a sua resposta por parte da Casa do Beco não têm carácter vinculativo, podendo sofrer alterações a qualquer momento e sem necessidade de aviso prévio.</p>
      <p>2. Confirmação de Reserva </p>
      <p>O processo de Reserva é feito em dois passos: após reserva efetuada através do nosso site esta vai ser confirmada por email com a indicação dos dados de pagamento. A confirmação só está concluída a ser confirmada a boa cobrança da estadia que vai ser também confirmada via email. O pagamento deve ser efetuado por transferência bancária. Caso não haja pagamento num prazo de 48h, a reserva fica sem efeito.</p>
      <p>3. Alteração de Datas </p>
      <p>A alteração das datas de uma reserva deve ser efetuada até 48h antes da data de check in, estando sempre sujeita à disponibilidade da Casa do Beco.</p>
      <p>4. Cancelamento de Estadia</p>
      <p>O cancelamento da estadia na Casa do Beco é gratuito quando efetuado até 3 dias antes da data check in, sendo nesta situação feita a devolução integral do valor pago. Caso o cancelamento seja feito após este período, será cobrado um terço do valor total da reserva.</p>
      <p>5. Reservas feitas por Sites Externos</p>
      <p>As reservas realizadas por canais externos obedecem as políticas em vigor nos sites onde foi realizada a reserva.</p>

      <p><b>Check in e Check out</b></p>
      <p>1. Horário</p>
      <p>A Casa do Beco não tem um serviço de Receção 24/7 e as normas de In/Out seguem o seguinte horário:</p>
      <p>Check IN: A partir das 14:00 – Check OUT – Até às 12:00</p>
      <p>2. Extra-Horário</p>
      <p>Qualquer situação fora desta regra deve ser previamente solicitada à Casa do Beco, bem como os referidos valores extra estadia a pagar.</p>
      <p><b>Hóspedes</b></p>
      <p>1. A Casa do Beco prepara o seu espaço tendo em conta as características da reserva, assim sendo o número de hóspedes presentes para estadia deve ser o mesmo que o previsto na reserva.</p>
    </div>
  )
}
