import React from 'react'

import { useTranslation } from 'react-i18next'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'

import useStyles from './styles'
import { ReactComponent as Logo } from '../../assets/icons/logo_1.svg'
import { ReactComponent as LanguageIcon } from '../../assets/icons/language.svg'
import LanguageMenu from '../LanguageMenu'

export default function Header() {
  const classes = useStyles()
  const { t } = useTranslation()
  const [anchorElLanguageMenu, setAnchorElLanguageMenu] = React.useState(null)

  const openLanguageMenu = (event) => {
    setAnchorElLanguageMenu(event.currentTarget)
  }

  const handleCloseLanguageMenu = () => {
    setAnchorElLanguageMenu(null)
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <LanguageMenu
          anchorEl={anchorElLanguageMenu}
          handleClose={handleCloseLanguageMenu}
        />
        <Grid
          container
          justify="space-beteween"
          alignItems="center"
        >
          <Grid
            container
            item
            xs={12}
            md={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item xs={3} md>
              <IconButton
                href="#home"
              >
                <Logo className={classes.logo} />
              </IconButton>
            </Grid>
            <Hidden smUp>
              <Grid
                container
                item
                xs={6}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={4}
                >
                  <IconButton
                    onClick={openLanguageMenu}
                  >
                    <LanguageIcon
                      style={{
                        width: '40px',
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          <Hidden smDown>
            <Grid
              container
              item
              md={11}
              alignItems="center"
            >
              <Grid item xs>
                <Divider
                  variant="fullWidth"
                  classes={{ root: classes.divider }}
                />
              </Grid>

              <Grid
                container
                item
                xs={12}
                md
                justify="space-evenly"
                alignItems="center"
              >
                <Grid item md>
                  <Button
                    fullWidth
                    href="#who-we-are"
                  >
                    {t('titles.who-we-are')}
                  </Button>
                </Grid>
                <Grid item md>
                  <Button
                    fullWidth
                    href="#services"
                  >
                    {t('titles.services')}
                  </Button>
                </Grid>
                <Grid item md>
                  <Button
                    fullWidth
                    href="#location"
                  >
                    {t('titles.location')}
                  </Button>
                </Grid>

                <Grid item>
                  <IconButton
                    fullWidth
                    onClick={openLanguageMenu}
                  >
                    <LanguageIcon style={{
                      width: '40px',
                      marginRight: '10px',
                    }}
                    />
                  </IconButton>
                </Grid>

              </Grid>
              <Grid item md={1}>
                <Divider
                  variant="fullWidth"
                  classes={{ root: classes.divider }}
                />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
