import React, { Suspense } from 'react'

import ThemeProvider from '@material-ui/core/styles/ThemeProvider'

import './i18n'
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom'
import { StylesProvider } from '@material-ui/core'
import theme from './theme'

import Home from './pages/Home'
import Loading from './components/Loading'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsConditions from './pages/TermsConditions'

function App() {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Suspense
          fallback={<Loading />}
        >
          <BrowserRouter>
            <Switch>
              <Route path="/terms-conditions" component={TermsConditions} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route component={Home} />
            </Switch>
          </BrowserRouter>
        </Suspense>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default App
