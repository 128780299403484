import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  appBar: {
    padding: theme.spacing(1, 0),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    height: '8vh',
    [theme.breakpoints.down('md')]: {
      height: '10vh',
      marginRight: '-18vh',
    },
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    height: '3px',
  },
}))
