import React from 'react'

// import Carousel from 'react-material-ui-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import Grid from '@material-ui/core/Grid'

import photo1 from '../../assets/photos/1.webp'
import photo2 from '../../assets/photos/2.webp'
import photo3 from '../../assets/photos/3.webp'
import photo4 from '../../assets/photos/4.webp'
import photo5 from '../../assets/photos/5.webp'
import photo6 from '../../assets/photos/6.webp'
import photo7 from '../../assets/photos/7.webp'
import photo8 from '../../assets/photos/8.webp'
import photo9 from '../../assets/photos/9.webp'
import photo10 from '../../assets/photos/10.webp'
import photo11 from '../../assets/photos/11.webp'
import photo12 from '../../assets/photos/12.webp'
import photo13 from '../../assets/photos/13.webp'
import photo14 from '../../assets/photos/14.webp'
import photo15 from '../../assets/photos/15.webp'
import photo16 from '../../assets/photos/16.webp'
import photo17 from '../../assets/photos/17.webp'
import photo18 from '../../assets/photos/18.webp'
import photo19 from '../../assets/photos/19.webp'

import useStyles from './styles'

function Photo({ item }) {
  const classes = useStyles()
  return (
    <div className={classes.image}>
      <img
        src={item.url}
        className={classes.image}
        alt="galery"
      />
    </div>
  )
}

export default function PhotoCarousel() {
  const classes = useStyles()
  const photos = [
    { url: photo1 },
    { url: photo2 },
    { url: photo3 },
    { url: photo4 },
    { url: photo5 },
    { url: photo6 },
    { url: photo7 },
    { url: photo8 },
    { url: photo9 },
    { url: photo10 },
    { url: photo11 },
    { url: photo12 },
    { url: photo13 },
    { url: photo14 },
    { url: photo15 },
    { url: photo16 },
    { url: photo17 },
    { url: photo18 },
    { url: photo19 },
  ]

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Carousel
          autoPlay
          infiniteLoop
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
        >
          {
            photos.map(
              (item, i) => <Photo key={i} item={item} />,
            )
          }
        </Carousel>
      </Grid>
    </Grid>
  )
}
