import React from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import IconButton from '@material-ui/core/IconButton'

import { useTranslation } from 'react-i18next'
import useStyles from './styles'
import TrustBox from '../TrustBox'

import { ReactComponent as Instagram } from '../../assets/icons/instagram.svg'

export default function Footer() {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Grid
      container
      className={classes.root}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        item
        xs={12}
        md={10}
        justifyContent="flex-start"
        alignItems="center"
      >

        <Grid item xs={12} md={2}>
          <Typography
            align="center"
            className={classes.footerText}
          >
            <Link
              color="secondary"
              align="center"
              className={classes.footerText}
              href="/terms-conditions"
            >
              {t('titles.terms-and-conditions')}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography
            align="center"
            className={classes.footerText}
          >
            <Link
              color="secondary"
              className={classes.footerText}
              href="/privacy-policy"
            >
              {t('titles.privacy-policy')}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography
            align="center"
            className={classes.footerText}
          >
            <Link
              color="secondary"
              href="https://www.livroreclamacoes.pt/inicio/reclamacao"
              className={classes.footerText}
            >
              {t('titles.complains-book')}
            </Link>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
        >
          <TrustBox />
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography
            className={classes.footerText}
            align="center"
          >
            Registo 113528/AL
          </Typography>
        </Grid>
        <Grid item xs={12} md={1}>
          <IconButton
            href="https://www.instagram.com/becocasa"
            style={{ display: 'block', align: 'center' }}
          >
            <Instagram style={{ width: '20px' }} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
      >
        <Grid item xs={10}>
          <Divider
            variant="fullWidth"
            classes={{ root: classes.divider }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          color="secondary"
          align="center"
          className={classes.footerText}
        >
          geral@casadobeco.com
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          color="secondary"
          align="center"
          className={classes.footerText}
        >
          © Casa do Beco
        </Typography>
      </Grid>

    </Grid>
  )
}
