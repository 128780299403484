import React from 'react'

import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

import { useTranslation } from 'react-i18next'
import DateRangePicker from '../DateRangePicker'
import useStyles from './styles'

const baseUrl = 'https://booking.smoobu.com/9A191322'

export default function BookingForm() {
  const [dates, setDates] = React.useState([null, null])
  const [error, setError] = React.useState(null)
  const [persons, setPersons] = React.useState(1)

  const handleChangePersons = (event) => {
    setPersons(event.target.value)
  }

  const dateToString = (date) => `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`

  const onBooking = () => {
    const [arrivalDate, departureDate] = dates
    setError(null)
    if (arrivalDate === null || departureDate == null) {
      setError(true)
      return
    }
    const queryString = `?arrivalDate=${dateToString(arrivalDate)}&departureDate=${dateToString(departureDate)}&adults=${persons}&children=0`
    const url = baseUrl + queryString
    window.gtag('event', 'page_view', {
      page_title: 'smoobu',
    })
    window.open(url, '_blank')
  }

  const { t } = useTranslation()
  const classes = useStyles()
  return (

    <Grid
      container
      className={classes.root}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        container
        item
        alignItems="baseline"
        justifyContent="space-between"
        spacing={2}
        md={9}
      >
        <Grid item xs={12} md={9}>
          <DateRangePicker
            id="departure"
            value={dates}
            onChange={setDates}
            label={t('arrival')}
            disablePast
            error={error}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputLabel
            shrink
            id="persons-label"
          >
            {t('common.persons')}
          </InputLabel>
          <Select
            labelId="persons-label"
            id="persons"
            value={persons}
            onChange={handleChangePersons}
            fullWidth
            style={{ marginBottom: '10px' }}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
          </Select>
        </Grid>
      </Grid>

      <Grid item xs={12} md={3}>
        <Button
          variant="contained"
          fullWidth
          onClick={onBooking}
        >
          Book
        </Button>
      </Grid>
    </Grid>
  )
}
