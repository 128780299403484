import React from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import Service from './componets/Service'
import { ReactComponent as OpenSpace } from '../../assets/icons/openspace.svg'
import { ReactComponent as Internet } from '../../assets/icons/internet.svg'
import { ReactComponent as Quartos } from '../../assets/icons/quartos.svg'
import { ReactComponent as Cozinha } from '../../assets/icons/cozinha.svg'
import { ReactComponent as Sala } from '../../assets/icons/sala.svg'

import useStyles from './styles'

export default function Services() {
  const classes = useStyles()
  const { t } = useTranslation()
  const services = [
    {
      icon: <OpenSpace />,
      title: t('services.2.title'),
      description: t('services.2.description'),
    },
    {
      icon: <Internet />,
      title: t('services.4.title'),
      description: t('services.4.description'),
    },
    {
      icon: <Sala />,
      title: t('services.3.title'),
      description: t('services.3.description'),
    },
    {
      icon: <Quartos />,
      title: t('services.1.title'),
      description: t('services.1.description'),
    },
    {
      icon: <Cozinha />,
      title: t('services.5.title'),
      description: t('services.5.description'),
    },
  ]

  return (
    <Grid
      container
      className={classes.root}
      justify="space-around"
    >
      <Grid item container alignItems="flex-start" spacing={3}>
        <Grid
          item
          xs={12}
          className={classes.title}
        >
          <Typography
            variant="h5"
            align="center"
            component="h1"
          >
            {t('titles.services')}
          </Typography>
        </Grid>
        {
            services.map((item) => (
              <Grid item xs={12} md={4} key={item.title}>
                <Service
                  service={item}
                  key={item.title}
                />
              </Grid>
            ))
        }
      </Grid>
    </Grid>
  )
}
