import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  container: {
  },
  root: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    margin: '0',
  },
  image: {
    width: '100%',
    height: '80vh',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: '350px',
    },
  },
  nextPreviousArrow: {
    content: '',
    display: 'block',
    width: '20px',
    height: '20px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    color: '#FFFFFF',
  },
}))
