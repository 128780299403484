import React, { useState } from 'react'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'
import ImageListItemBar from '@material-ui/core/ImageListItemBar'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import MapGL, { Marker, NavigationControl } from 'react-map-gl'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/logo_512x512.webp'
import useStyles from './styles'

import praiaFluvial from '../../assets/photos/praia_fluvial.webp'
import serraEstrela from '../../assets/photos/serra_estrela.webp'
import cornosDiabo from '../../assets/photos/cornos_do_diabo.webp'
import quedasCanica from '../../assets/photos/quedas_canica.webp'
import covaoConchos from '../../assets/photos/covao_conchos.webp'
import soutosLapa from '../../assets/photos/soutos_lapa.webp'

const navStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px',
}

const MAP_API_KEY = 'pk.eyJ1IjoicnVpbWdmIiwiYSI6ImNrZ2FwNm10ZDA5N3cycnJ0eWN6MXVia3QifQ.Pk7Ju-12b-J_uTV2wnA0Lw'

const itemData = [
  {
    img: praiaFluvial,
    title: 'Praia Fluvial Lapa dos Dinheiros',
  },
  {
    img: quedasCanica,
    title: 'Quedas da Caniça',
  },
  {
    img: soutosLapa,
    title: 'Soutos da Lapa dos Dinheiross',
  },
  {
    img: cornosDiabo,
    title: 'Cornos do Diabo',
  },
  {
    img: covaoConchos,
    title: 'Covão dos Conchos',
  },
  {
    img: serraEstrela,
    title: 'Serra da Estrela',
  },

]

export default function Location() {
  const classes = useStyles()
  const { t } = useTranslation()
  const [viewport, setViewport] = useState({
    latitude: 40.378204,
    longitude: -7.708201,
    zoom: 15,
    bearing: 0,
    pitch: 0,
  })
  // eslint-disable-next-line
  const [interactionState, setInteractionState] = useState({})
  // eslint-disable-next-line
  const [settings, setSettings] = useState({
    dragPan: true,
    dragRotate: true,
    scrollZoom: true,
    touchZoom: true,
    touchRotate: true,
    keyboard: true,
    doubleClickZoom: true,
    minZoom: 5,
    maxZoom: 20,
    minPitch: 0,
    maxPitch: 85,
  })
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  const renderMarker = (station, i, zoom) => {
    const { coordinates } = station
    const width = (zoom / 15) * 8
    return (
      <Marker
        key={i}
        longitude={coordinates[0]}
        latitude={coordinates[1]}
        captureDrag={false}
        captureDoubleClick={false}
      >
        <img
          src={logo}
          style={{ width: `${width}%` }}
          alt="logo"
        />
      </Marker>
    )
  }
  return (
    <Grid container>
      <Grid
        container
        item
        justifyContent="space-evenly"
        alignItems="center"
        className={classes.root}
        spacing={3}
      >
        <Grid item xs={12} className={classes.title}>
          <Typography
            variant="h5"
            align="center"
            component="h1"
          >
            {t('titles.location')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.description}>
          <Typography
            variant="body2"
            align="justify"
          >
            {t('location.p1')}
          </Typography>
          <Typography
            variant="body2"
            align="justify"
          >
            {t('location.p2')}
          </Typography>
          <Typography
            variant="body2"
            align="justify"
          >
            {t('location.p3')}
          </Typography>
          <Typography
            variant="body2"
            align="justify"
          >
            {t('location.p4')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <MapGL
            {...viewport}
            {...settings}
            height="300px"
            width="100%"
            mapStyle="mapbox://styles/mapbox/streets-v11"
            onViewportChange={(viewport) => setViewport(viewport)}
            onInteractionStateChange={setInteractionState}
            mapboxApiAccessToken={MAP_API_KEY}
          >
            {renderMarker({ coordinates: [-7.710, 40.379] }, 0, viewport.zoom)}
            <div className="nav" style={navStyle}>
              <NavigationControl onViewportChange={setViewport} />
            </div>
          </MapGL>
        </Grid>
        <Grid item xs={12}>
          <ImageList
            className={classes.imageGalleryContainer}
            cols={smUp ? 3 : 1}
          >
            {itemData.map((item) => (
              <ImageListItem key={item.title}>
                <img
                  srcSet={`${item.img}`}
                  alt={item.title}
                />
                <ImageListItemBar
                  title={item.title}
                  classes={{
                    root: classes.titleWrap,
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </Grid>
    </Grid>
  )
}
