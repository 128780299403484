import React from 'react'

import { useTranslation } from 'react-i18next'

import Flag from 'react-world-flags'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

export default function LanguageMenu({ anchorEl, handleClose }) {
  const { i18n } = useTranslation()
  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    handleClose()
  }
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={() => changeLanguage('pt')}>
        <Flag code="pt" width="30" />
      </MenuItem>
      <MenuItem onClick={() => changeLanguage('en')}>
        <Flag code="us" width="30" />
      </MenuItem>
      {/* <MenuItem onClick={() => changeLanguage('fr')}>
        <Flag code="fr" width="30" />
      </MenuItem> */}
    </Menu>
  )
}
