import React from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { useTranslation } from 'react-i18next'

import useStyles from './styles'

export default function WhoWeAre() {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
      <Grid item xs={12} md={6}>
        <iframe
          width="99%"
          height="315"
          style={{ align: 'center' }}
          src="https://www.youtube.com/embed/Iemk4E2QVwQ"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Grid>
      <Grid container item md={6}>
        <Grid item xs={12} className={classes.title}>
          <Typography
            variant="h5"
            align="center"
            component="h1"
          >
            Casa do Beco
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.description}>
          <Typography
            variant="body2"
            align="justify"
            style={{ marginBottom: '10px' }}
          >
            {t('who-we-are.p1')}
          </Typography>
          <Typography
            variant="body2"
            align="justify"
            style={{ marginBottom: '10px' }}
          >
            {t('who-we-are.p2')}
          </Typography>
          <Typography
            variant="body2"
            align="justify"
            style={{ marginBottom: '10px' }}
          >
            {t('who-we-are.p3')}
          </Typography>
          <Typography
            variant="body2"
            align="justify"
            style={{ marginBottom: '10px' }}
          >
            {t('who-we-are.p4')}
          </Typography>
          <Typography
            variant="body2"
            align="justify"
            style={{ marginBottom: '10px' }}
          >
            {t('who-we-are.p5')}
          </Typography>
          <Typography
            variant="body2"
            align="justify"
            style={{ marginBottom: '10px' }}
          >
            {t('who-we-are.p6')}
          </Typography>
          <Typography
            variant="body2"
            align="justify"
          >
            {t('who-we-are.p7')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
