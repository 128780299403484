import React, { useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import PhotoCarousel from '../../components/PhotoCarousel'

import BookingForm from '../../components/BookingForm'
import Services from '../../components/Services'
import TawkBot from '../../components/TawkBot'
import Location from '../../components/Location'
import WhoWeAre from '../../components/WhoWeAre'

import useStyles from './styles'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export default function Home() {
  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_title: 'home',
    })
  }, [])

  const classes = useStyles()
  return (
    <>
      <Header />
      <Grid
        container
        className={classes.root}
        id="home"
      >
        <Grid item className={classes.photoCaroselContainer}>
          <PhotoCarousel />
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="center"
          className={classes.container}
        >
          <Grid
            item
            xs={12}
            md={10}
            className={classes.bookingFormContainer}
            id="book"
          >
            <BookingForm />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            className={classes.whoWeAreContainer}
            id="who-we-are"
          >
            <WhoWeAre />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            className={classes.servicesContainer}
            id="services"
          >
            <Services />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            className={classes.locationContainer}
            id="location"
          >
            <Location />
          </Grid>
        </Grid>
        <TawkBot />
      </Grid>
      <Footer />
    </>
  )
}
