import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    marginTop: '9vh',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10vh',
    },
    width: '100vw',
  },
  container: {
    marginTop: '-6vh',
    background: '#FFFFFF',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      marginTop: '0px',
      padding: '20px',
    },
  },
  photoCaroselContainer: {
    height: '90vh',
    [theme.breakpoints.down('sm')]: {
      height: '350px',
    },
  },
  bookingFormContainer: {
    marginTop: '-50px',
    zIndex: 2,
    marginBottom: '100px',
    height: '100px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
      marginBottom: '150px',
    },
  },
  whoWeAreContainer: {
    marginBottom: '50px',
  },
  servicesContainer: {
    marginBottom: '50px',
  },
  locationContainer: {
    marginBottom: '50px',
  },
}))
