import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  root: {
    color: 'black',
  },
  icon: {
    fontSize: '5.5rem',
  },
  title: {
    color: '#333333',
    marginBottom: '3px',
  },
  description: {
    color: '#888888',
  },
}))
