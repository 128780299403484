import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    background: '#FFFFFF',
  },
  title: {
    color: '#333333',
    textTransform: 'uppercase',
    paddingBottom: '20px',
  },
  imageGalleryContainer: {
    paddingTop: '20px',
    width: '100%',
    height: '100%',
  },
  description: {
    color: '#888888',
    paddingBottom: '20px',
  },
  titleWrap: {
    backgroundColor: 'rgba(0,0,0,0)',
  },
}))
