import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'

export default function Service({ service: { title, description, icon } }) {
  const classes = useStyles()
  return (
    <Grid
      container
      className={classes.root}
      alignItems="center"
      justifyContent="space-evenly"
      spacing={2}
    >
      <Grid item xs={2}>
        {icon}
      </Grid>
      <Grid container item xs={10}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            align="center"
            className={classes.title}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            align="justify"
            className={classes.description}
          >
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
